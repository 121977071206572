<template>
  <div>
    <div class="page-fund-header">
      <h3>SAASTR Europa 2022 Attendees List</h3>

      <p>
        You can check the SAASTR Europa 2022 Attendees List. It's completely
        free. Enjoy! <br />
        Here is the search engine link :

        <a target="_blank" href="https://saastr.softr.app/"
          >https://saastr.softr.app</a
        >

        <br />

        Here is the the airtable Link :

        <a target="_blank" href="https://airtable.com/shrWPBaC93JqVd2Fz"
          >https://airtable.com/shrWPBaC93JqVd2Fz</a
        >

        <!-- <a target="_blank" href="https://saastr.softr.app/">here</a><br /> It's completely free. Enjoy! <br /> This table is updated daily. Are you curious to see how it works?
    
                <a target="_blank" href="https://airtable.com/shrWPBaC93JqVd2Fz">Click here</a
    
            > -->
      </p>
    </div>

    <div class="table-hadding">
      <h3>Attendees List</h3>
    </div>

    <iframe
      class="airtable-embed"
      src="https://airtable.com/embed/shrWPBaC93JqVd2Fz?backgroundColor=yellow&viewControls=on"
      frameborder="0"
      onmousewheel=""
      width="100%"
      height="533"
      style="background: transparent; border: 1px solid #ccc;"
    ></iframe>
  </div>
</template>
<script>
import { loadingService } from "../../common/services/loading.service";
export default {
  name: "fundraising-page",
  data() {
    return {};
  },
  async beforeMount() {
    this.$eventBus.$emit("callCreditMethod");
  },
  mounted() {
    loadingService.setloader(true);
    setTimeout(() => {
      loadingService.setloader(false);
    }, 2000);
  },
  methods: {},
};
</script>
<style scoped>
.page-fund-header {
  padding: 40px 60px;
  margin: 0 0 40px;
  background: rgb(247 248 250);
}

.table-hadding {
  padding: 4px 60px;
}
</style>
